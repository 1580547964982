<template>
  <!--  <v-list-item class="mb-0 justify-space-between pl-3">-->
  <!--    <v-list-item-avatar>-->
  <!--      <v-img-->
  <!--        :src="-->
  <!--          require('@/assets/images/goldair_cargo.png')"-->
  <!--      />-->
  <!--    </v-list-item-avatar>-->

  <!--    <v-list-item-content class="pl-2">-->
  <!--      <v-list-item-title class="text-h3">-->

  <!--      </v-list-item-title>-->
  <!--    </v-list-item-content>-->
  <!--  </v-list-item>-->
  <v-btn
    :to="{ path:'/' }"
    class="drawer-logo-wrapper"
  >
    <v-img
      v-if="!mini"
      :src="require('@/assets/images/goldair_cargo.png')"
    />
    <v-img
      v-if="mini"
      :src="require('@/assets/images/g.png')"
    />
  </v-btn>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'DefaultDrawerHeader',
    computed: {
      ...sync('app', [
        'mini',
      ]),
    },
  }
</script>
